import React, { useContext, useEffect, useRef, useState } from 'react'
import { MainContext } from "../context/context"

export default ({ 
    title, 
    preTitle, 
    button 
}) => {
    const [hasMounted, setHasMounted] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setHasMounted(true)
        },200)
    },[])

	const {
        options,
        addInvertedHeaderSection
    } = useContext(MainContext)

    const elementRef = useRef(null)
    
    useEffect(() => {
        if(elementRef && elementRef.current){
            addInvertedHeaderSection(elementRef.current)
        }
    }, [elementRef])

    return (
        <div 
            className={`mini-hero ${ hasMounted ? 'mounted' : '' }`}
            style={{
                backgroundImage: `url('${ options.conversionForm.heroBackgroundImage.sourceUrl }')`
            }}
            ref={ref => elementRef.current = ref}
        >
            <div className="container">
                <div className="column left">
                    <h4>
                        {preTitle}
                    </h4>
                    <h1>
                        {title}
                    </h1>
                </div>
                { button &&
                    <div className="column right">
                        <a className="button" href={button.url}>
                        {button.label}
                        </a>
                    </div>
                }
            </div>
        </div>
    )
}
