import React, { useEffect, useState, useContext } from "react"

import { MainContext } from 'context/context'

import MiniHero from "components/miniHero"
import { useQueryParam, NumberParam } from "use-query-params"

import Form from "components/form"
import SEO from "components/seo"

import { navigate } from 'gatsby'
import { getJob } from 'utils'

export default ({}) => {
    const jobId = useQueryParam("id", NumberParam)[0]
    const [formFields, setFormFields] = useState([])
    const [job, setJob] = useState(null)
    const {
        openModal
    } = useContext(MainContext)

    useEffect(() => {
        if(!jobId){
            if(typeof window !== 'undefined'){
                handleError()
            }
        }else{
            getJob(jobId)
            .then(({data}) => {
                setJob(data.job)

                const _formFields = [{
                    rows: [
                        {
                            columns: [
                                {
                                    label: `First name`,
                                    type: `text`,
                                    required: true,
                                },{
                                    label: `Last name`,
                                    type: `text`,
                                    required: true,
                                }
                            ]
                        },{
                            columns: [
                                {
                                    label: `Email address`,
                                    type: `email`,
                                    required: true,
                                },{
                                    label: `Phone number`,
                                    type: `text`,
                                    required: true,
                                }
                            ]
                        },{
                            columns: [
                                {
                                    label: `Suburb and State`,
                                    type: `text`,
                                    required: true,
                                }
                            ]
                        },{
                            columns: [
                                {
                                    label: `Upload your CV here`,
                                    type: `file`,
                                    required: true
                                },{
                                    label: `Upload a cover letter (optional)`,
                                    type: `file`,
                                }
                            ]
                        }
                    ]
                }]

                data.job.questions.forEach(question => {
                    const {
                        required,
                        title,
                        type,
                        field_choices
                    } = question

                    switch(type){
                        case 'CheckBoxField':
                        case 'RadioButtonField':
                            let optionsString = ``

                            field_choices.forEach(option => {
                                optionsString += `${ option.name }
                                `
                            })

                            _formFields[0].rows.push({
                                columns: [{
                                    label: title,
                                    options: optionsString,
                                    type: type === 'CheckBoxField' ? `checkboxes` : `radios`,
                                    required
                                }]
                            })
                            break
                    }
                })

                setFormFields(_formFields)
            }).catch(error => {
                handleError()
            })
        }
    }, [])

    const handleError = _ => {
        navigate('/')

        openModal({
            type: 'CONFIRMATION',
            subType: `JOB_NOT_FOUND`
        })
    }

    return (
        <>
            <SEO title={'Job Application'} />
            <div className="form-page">
                <MiniHero 
                    title={job ? job.title : ''} 
                    preTitle={'Applying for'} 
                />
                <div className="container">
                    <Form 
                        fields={formFields}
                        submissionTitle={'Job Application'}
                        jobtitle={job ? job.title : ''}
                    />
                </div>
            </div>
        </>
    )
}